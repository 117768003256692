@import "./variables";
@import "./mixins";

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  height: 100%;
  overflow-x: hidden;
}

.navbar-toggler {
  border: none !important;
}

.disabled-page {
  color: #808e9b;
}

.dropdown-toggle {
  color: $secondaryColor !important;
}

img#organization {
  height: 200px;
}

img#organization_detail {
  height: 100%;
}

img#hero {
  width: 100%;
  object-fit: contain;
  box-shadow: none !important;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("./../assets/placeholder.png");
  mask-image: url("./../assets/placeholder.png");
  mask-position: center;
  mask-repeat: no-repeat no-repeat !important;
  -webkit-mask-repeat: no-repeat no-repeat;
  -moz-mask-repeat: no-repeat no-repeat;
  -ms-mask-repeat: no-repeat no-repeat;
  // position: absolute;
  // z-index: 2;
}
img#hero-blur {
  width: 100%;
  object-fit: cover;
  box-shadow: none !important;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("./../assets/placeholder.png");
  mask-image: url("./../assets/placeholder.png");
  mask-position: center;
  mask-repeat: no-repeat no-repeat !important;
  -webkit-mask-repeat: no-repeat no-repeat;
  -moz-mask-repeat: no-repeat no-repeat;
  -ms-mask-repeat: no-repeat no-repeat;
  position: absolute;
  left: 0;
  z-index: -1;
  filter: blur(8px);
}

img#whoweare {
  object-fit: contain;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("./../assets/place2.png");
  mask-image: url("./../assets/place2.png");
  mask-position: center;
  mask-repeat: no-repeat no-repeat !important;
  -webkit-mask-repeat: no-repeat no-repeat;
  -moz-mask-repeat: no-repeat no-repeat;
  -ms-mask-repeat: no-repeat no-repeat;
}
img#whoweare-blur {
  object-fit: cover;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-image: url("./../assets/place2.png");
  mask-image: url("./../assets/place2.png");
  mask-position: center;
  mask-repeat: no-repeat no-repeat !important;
  -webkit-mask-repeat: no-repeat no-repeat;
  -moz-mask-repeat: no-repeat no-repeat;
  -ms-mask-repeat: no-repeat no-repeat;
  position: absolute;
  left: 0;
  z-index: -1;
  filter: blur(8px);
}

.nav-tabs {
  border-bottom: none !important;
  .nav-link {
    background-color: $primaryColor !important;
    padding: 12px 24px;
    &.active {
      background-color: $fourthColor !important;
      color: #fff !important;
      border-radius: 12px;
      font-weight: 700;
      border: none !important;
    }
  }
}

.tab-content {
  background-color: $primaryColor !important;
  padding: 42px 24px !important;
  border-radius: 0 0 24px 24px !important;
}

a {
  text-decoration: none !important;
}

.modal-body img {
  box-shadow: none !important;
}

.next {
  font-size: 4px;
  height: 60px;
  position: absolute;
  right: 0;
  width: 150px;
}

.loading-container {
  height: 100vh;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #808e9b42;
}

.pagination {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 60px;
  justify-content: center;
  list-style-type: none;
  position: relative;
  width: 1000px;
  .item {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    height: 40px;
    justify-content: center;
    width: 40px;
    &:first-child {
      width: auto;
      a {
        text-decoration: none;
      }
    }
    &:last-child {
      a {
        text-decoration: none;
      }
      width: auto;
    }
    &.active {
      background-color: $secondaryColor;
      border-radius: 40px;
      color: $primaryColor;
    }
  }
}

.pagination-page {
  font-weight: 700;
}

.previous {
  font-size: 4px;
  height: 60px;
  left: 0;
  position: absolute;
  width: 150px;
}

.hero {
  border-radius: 0;
  margin-bottom: 72px;
  @include desktop() {
    margin-bottom: 0;
    height: 100%;
    &__custom {
      margin-bottom: 0;
      height: 100%;
    }
    &__sticky {
      margin-bottom: 0;
      height: 100%;
    }
  }
  @include desktopLarge() {
    &__custom {
      height: 100% !important;
    }
    &__sticky {
      height: 100%;
    }
  }
  position: relative;
  &__custom {
    border-radius: 0;
    margin-bottom: 42px;
    height: 100%;
    background-color: $fifthColor;
  }
  &__sticky {
    border-radius: 0;
    height: 100%;
    background-color: $fifthColor;
  }
}

// leaflet
.leaflet-container {
  height: 70vh;
  @include desktop() {
    height: 60vh;
  }
  position: relative;
}

// dropdown
.dropdown-menu {
  padding: 0;
  border: none !important;
  background: transparent;
  @include desktop() {
    box-shadow: 0px 20px 24px -12px rgba(84, 84, 84, 0.1);
  }
  .dropdown-item {
    padding: 6px 16px;
  }
}

// form
.form-label {
  font-weight: $font600;
  font-size: $nav;
  margin-bottom: 12px;
}
input {
  &.form-control {
    background: #f3f3f3 !important;
    border-radius: 8px;
    border: none !important;
    height: 50px;
    margin-bottom: 24px;
  }
}
textarea {
  &.form-control {
    background: #f3f3f3 !important;
    border-radius: 8px;
    border: none !important;
    margin-bottom: 24px;
  }
}

// Scrollbar

/* width */
::-webkit-scrollbar {
  width: 5px;
  padding: 4px;
  background-color: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $thirdColor;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $secondaryColor;
  cursor: pointer;
  background-color: transparent;
}
